<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Reklama Ruxsatnomalari o'zgartirish</h3>
          </div>
        </div>
        <div class="card-body">
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="3" class="pl-0">
                <v-autocomplete
                  :items="truckList"
                  v-model="truck"
                  :error-messages="truckErrors"
                  item-text="name"
                  item-value="id"
                  label="Mashina raqami"
                  :loading="isLoading"
                  outlined
                  @input="$v.truck.$touch()"
                  @blur="$v.truck.$touch()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3" class="pl-0">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :error-messages="permittedErrors"
                      v-model="permitted"
                      label="Boshlanish sanasi"
                      prepend-inner-icon="event"
                      readonly
                      v-bind="attrs"
                      outlined
                      v-on="on"
                      @input="$v.permitted.$touch()"
                      @blur="$v.permitted.$touch()"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="permitted"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="3" class="pl-0">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :error-messages="expiresErrors"
                      v-model="expires"
                      label="Tugash sanasi"
                      prepend-inner-icon="event"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      @input="$v.expires.$touch()"
                      @blur="$v.expires.$touch()"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="expires"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="3" class="pl-0">
                <v-text-field
                  :error-messages="reg_numberErrors"
                  v-model="reg_number"
                  label="Reg raqami"
                  outlined
                  @input="$v.reg_number.$touch()"
                  @blur="$v.reg_number.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <div class="d-flex justify-content-start border-top pt-10">
            <div class="mr-2">
              <button
                type="button"
                class="
                  btn btn-light-primary
                  font-weight-bold
                  text-uppercase
                  px-9
                  py-4
                "
                @click="save"
              >
                Saqlash
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style></style>

<script>
import Swal from 'sweetalert2'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
// import debounce from "debounce";
import { required } from 'vuelidate/lib/validators'
export default {
  data() {
    return {
      menu: false,
      memu2: false,
      expires: '',
      permitted: '',
      reg_number: '',
      truck: ''
    }
  },
  validations: {
    expires: {
      required
    },
    permitted: {
      required
    },
    reg_number: {
      required
    },
    truck: {
      required
    }
  },
  created() {
    this.$store.dispatch('getAdvertPermitsDetails', this.$route.params.id)
    this.$store.dispatch('getTruckLists')
  },
  watch: {
    advertPermitsDetails: function (val) {
      this.truck = val.truck_info
      this.reg_number = val.reg_number
      this.permitted = val.permitted
      this.expires = val.expires
    }
  },
  computed: {
    truckList() {
      const data = this.$store.state.requests.allTruckList
      data.forEach((x) => {
        x.name = x.reg_number_region + ' ' + x.reg_number
      })
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return data
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    advertPermitsDetails() {
      const data = this.$store.state.requests.advertPermitsDetails
      if (data.truck_info !== undefined) data.truck_info = data.truck_info.id
      return data
    },
    truckErrors() {
      const errors = []
      if (!this.$v.truck.$dirty) return errors

      !this.$v.truck.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    reg_numberErrors() {
      const errors = []
      if (!this.$v.reg_number.$dirty) return errors

      !this.$v.reg_number.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    permittedErrors() {
      const errors = []
      if (!this.$v.permitted.$dirty) return errors

      !this.$v.permitted.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    expiresErrors() {
      const errors = []
      if (!this.$v.expires.$dirty) return errors

      !this.$v.expires.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Reklama Ruxsatnomalari o'zgartirish" }
    ])
  },
  methods: {
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        Swal.fire({
          title: 'Xato',
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      } else {
        this.submitStatus = 'PENDING'
        this.submitStatus = 'OK'
        const data = {
          truck: this.truck.id,
          permitted: this.permitted,
          reg_number: this.reg_number,
          expires: this.expires
        }
        this.$store.dispatch('updateAdvertPermits', {
          data: data,
          id: this.$route.params.id
        })
        this.truck = ''
        this.permitted = ''
        this.reg_number = ''
        this.expires = ''
        this.$v.$reset()
      }
    }
  }
}
</script>
